import React, { FC } from 'react';
import styled from "styled-components";
import LanguageSelector from "../i18n/LanguageSelector";

const Wrapper = styled.footer`
  font-family: 'Courier prime', monospace;
  font-weight: 700;
  text-align: center;
  font-size: 1rem;
  padding: 1rem;
  border: 3px solid ${({ theme }) => theme.colors.dark};
  background: ${({ theme }) => theme.colors.light};
`;

const Paragraph = styled.p`
  margin-bottom: 0;
  line-height: 1.1;
`;

interface Props {
  hideLanguageSelector?: boolean;
}

const Footer: FC<Props> = ({
  hideLanguageSelector = false,
}) => {
  return (
    <Wrapper>
      <Paragraph>
        SEE | MEET | BE
      </Paragraph>
      <Paragraph>
        THE S’AINT
      </Paragraph>
      <Paragraph>
        Member of the Social Contemporary Group
      </Paragraph>
      <Paragraph>
        Calea Victoriei 139 | +40 733 536 349
      </Paragraph>
      {!hideLanguageSelector && <LanguageSelector />}
    </Wrapper>
);
}

export default Footer;
