import { Location } from '../menu-page/types';

const locationToEndpointSegment = {
  'the-saint': 'saint',
  'the-other-saint': 'o-saint'
}

// const BASE_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8080';
const BASE_URL = process.env.NODE_ENV === 'production' ? '' : 'https://thesaint.ro';

const doRequest = (endpoint: string) => {
  return fetch(`${BASE_URL}${endpoint}`, {
    method: 'GET',
  })
    .then(response => response.json())
    .then(data => {
      return data;
    });
}


interface GetCategoriesParams {
  location?: string;
  category?: string;
}

const customOrderFoodRules = [
  'breakfast',
  'starters',
  'vegan',
  'main-course',
  'sides',
  'salads',
  'dessert',
];

const customOrderDrinkRules = [
  "brunch-drinks",
  "summer-drinks",
  "signature-cocktails",
  "cocktails",
  "classic-cocktails",
  "sours",
  "campari-classics",
  "gin-tonic",
  "highballs",
  "spritz",
  "holy-shots",
  "spirits",
  "wines",
  "beer-cider",
  "mocktails-lemonade",
  "softs-holy-water",
  "coffee-tea",
  "cigarettes",
];

export const sortByCustomRules = (category: GetCategoriesParams['category']) => (a: any, b: any) => {
  if (category === 'food') {
    return customOrderFoodRules.indexOf(a.slug) - customOrderFoodRules.indexOf(b.slug);
  }

  if (category === 'drinks') {
    return customOrderDrinkRules.indexOf(a.slug) - customOrderDrinkRules.indexOf(b.slug);
  }

  return 0;
}
export const getSubcategories = async ({
  location,
  category
}: GetCategoriesParams) => {
  if(!location) {
    throw new Error('Location is required to get subcategories');
  }

  if(!category) {
    throw new Error('Parent category is required to get subcategories');
  }


  const data = await doRequest(`/wp-json/custom-taxonomy-block-editor/v1/${locationToEndpointSegment[location as Location]}-categories?parent=${category}`);

  return data.sort(sortByCustomRules(category));
}

export const getRelatedProducts = (productId: number) => {
  return doRequest(`/wp-json/product/${productId}/related-products`);
}

export const getMenuPageBlocks = (location: Location) => {
  return doRequest(`/wp-json/custom-taxonomy-block-editor/v1/${locationToEndpointSegment[location]}`);
}
