import React, { FC } from "react";
import Heading from "../../layout/Heading";
import { FormattedMessage } from "react-intl";
import Subheading from "../../layout/Subheading";
import styled from "styled-components";

const Wrapper = styled.div`
  text-align: center;
`;

const NoticeDescription = styled.p`
  font-family: "Courier Prime", serif;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1em;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const HappyHour:FC = () => {
  return (
    <Wrapper>
      <Heading>
        <FormattedMessage id="menu.the.saint.happy.hour.title" />
      </Heading>
      <Subheading>
        <FormattedMessage id="menu.the.saint.happy.hour.subheading0" />
      </Subheading>
      <Subheading>
        <FormattedMessage id="menu.the.saint.happy.hour.subheading1" />
      </Subheading>
      <NoticeDescription>
        <FormattedMessage id="menu.the.saint.happy.hour.description" />
      </NoticeDescription>
    </Wrapper>
  )
}

export default HappyHour;
