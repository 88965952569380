import { createContext, FC, PropsWithChildren, useState } from "react";
import { Locale } from "./types";
import { IntlProvider } from 'react-intl';
import roLocale from "./translations/ro.json";
import enLocale from "./translations/en.json";

const translatedMessages = {
  [Locale.RO]: roLocale,
  [Locale.EN]: enLocale
};

interface ContextFields {
  locale: Locale;
  setLocale: (locale: Locale) => void;
}
export const I18NContext = createContext<ContextFields>({
  locale: Locale.EN,
  setLocale: () => {}
});

// try to guess the preferred language from the browser
const getPreferredLocale = (): Locale => {
  // try to get the preferred language from localStorage
  const localStorageLocale = localStorage.getItem("locale");
  if (localStorageLocale) {
    return localStorageLocale as Locale;
  }

  const browserLocale = navigator.language;
  if (browserLocale.includes("ro")) {
    return Locale.RO;
  }

  return Locale.EN;
}
const I18NProvider: FC<PropsWithChildren> = ({ children }) => {
  const [locale, setLocale] = useState<Locale>(getPreferredLocale());

  const handleLocaleChange = (locale: Locale) => {
    setLocale(locale);
    localStorage.setItem("locale", locale);
  }

  return (
    <I18NContext.Provider value={{
      locale,
      setLocale: handleLocaleChange
    }}>
      <IntlProvider
        locale={locale}
        defaultLocale="ro"
        messages={translatedMessages[locale]}
      >
        {children}
      </IntlProvider>
    </I18NContext.Provider>
  );
};

export default I18NProvider;
