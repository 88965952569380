import Modal, {
  Header,
  Picture,
  SectionContainer
} from "../../../layout/Modal";
import NutritionalValuesTable from "./NutritionalValuesTable";
import AllergensList from "./AllergensList";
import React, { FC } from "react";
import { Product } from "../../types";
import ModalSection from "../../../layout/Modal/ModalSection";
import { FormattedMessage } from "react-intl";
import useBrowserBackBlocking from "../../useBrowserBackBlocking";

interface Props {
  item: Product;
  onClose: () => void;
}

const ProductDetails: FC<Props> = ({
  item,
  onClose,
}) => {
  useBrowserBackBlocking({
    onBack: () => onClose(),
  });

  return (
    <Modal
      header={item.name}
      onClose={onClose}
    >
      {item.thumbnail && (
        <ModalSection>
          <SectionContainer>
            <Picture src={item.thumbnail}/>
          </SectionContainer>
        </ModalSection>
      )}
      {item.allergens.length > 0 && (
        <ModalSection>
          <SectionContainer>
            <Header variant="primary">
              <FormattedMessage id="menu.product.details.allergens.title"/>
            </Header>
            <AllergensList
              items={item.allergens}
              frozenAllergens={item.frozenAllergens}
            />
          </SectionContainer>
        </ModalSection>
      )}
      {item.nutrition.length > 0 && (
        <ModalSection>
          <SectionContainer>
            <Header variant="primary">
              <FormattedMessage id="menu.product.details.nutritional.values.title"/>
            </Header>
            <NutritionalValuesTable product={item}/>
          </SectionContainer>
        </ModalSection>
      )}
    </Modal>
  )
}

export default ProductDetails;
