import React from 'react';
import styled from "styled-components";
import { Navigate, Route, Routes } from "react-router-dom";
import MenuPage from "../menu-page";
import Categories from "../menu-categories";
import MenuSubcategories from "../menu-subcategories";
import LandingPage from "../landing-page";

const Container = styled.div`
  padding: 0;
`;

function App() {
  return (
    <Container>
      <Routes>
        <Route path="/">
          <Route index element={
            <Navigate to="/menu" />
          } />
          <Route path="/menu" element={
            <LandingPage />
          } />
          <Route path="/menu/:location">
            <Route index element={<Categories />}/>
            <Route path=":category" element={<MenuSubcategories />} />
            <Route path=":category/:subcategory" element={<MenuPage /> } />
          </Route>
        </Route>
      </Routes>
    </Container>
  );
}

export default App;
