import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const Wrapper = styled.div`
  border: 3px solid ${({ theme }) => theme.colors.dark};
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const spinnerAnimation = keyframes`
  0% {
    stroke-dashoffset: 250;
  }
  
  100% {
    stroke-dashoffset: -280;
  }
`;

const Logo = styled.svg`
  animation: ${spinnerAnimation} 1.5s ease-out infinite;
  stroke-dasharray: 280%;
  stroke-dashoffset: 0;
  filter: drop-shadow(0px 0px 6px ${({ theme }) => theme.colors.primaryGlow});
`;

const Loader: FC = () => {
  return (
    <Wrapper>
      <Logo width="250" height="60" viewBox="0 0 136 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M68.3784 15.7188C86.265 15.4134 102.466 14.5584 114.205 13.42C120.067 12.8516 124.855 12.2088 128.196 11.5196C129.855 11.1774 131.228 10.8096 132.213 10.4059C132.698 10.2072 133.179 9.96452 133.563 9.65247C133.919 9.36245 134.456 8.79102 134.441 7.93004C134.426 7.06906 133.871 6.51627 133.504 6.2386C133.11 5.93983 132.622 5.71368 132.13 5.53172C131.131 5.16184 129.746 4.84121 128.077 4.55584C124.715 3.98107 119.907 3.50219 114.029 3.13422C102.258 2.39735 86.0376 2.09608 68.1509 2.40154C50.2643 2.70701 34.0633 3.56197 22.3243 4.70035C16.4624 5.26881 11.6738 5.91157 8.33331 6.60081C6.67474 6.94301 5.30159 7.31074 4.31579 7.71452C3.83086 7.91315 3.35012 8.15586 2.96648 8.46791C2.60994 8.75792 2.07362 9.32936 2.08832 10.1903C2.10302 11.0513 2.65855 11.6041 3.02479 11.8818C3.41885 12.1805 3.9076 12.4067 4.39903 12.5887C5.39804 12.9585 6.78295 13.2792 8.45223 13.5645C11.8144 14.1393 16.6221 14.6182 22.5 14.9862C34.271 15.723 50.4917 16.0243 68.3784 15.7188Z" stroke="#EFD749" strokeWidth="3"/>
      </Logo>
    </Wrapper>
  );
}

export default Loader;
