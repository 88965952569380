import React, { FC, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { CustomBlock, Product } from "../types";
import { cleanProductData } from "../utils";
import { I18NContext } from "../../i18n";
import ProductDetails from "./product-details";
import RelatedProducts from "./related-products";
import getImagePath from "../../../utils";


const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;


const TitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75em;
`;


const Title = styled.h3<{ disabled: boolean }>`
  color: ${({theme}) => theme.colors.primary};
  font-weight: bold;
  font-size: 1em;
  text-transform: uppercase;
  text-decoration: underline;
  margin-bottom: 0;
  cursor: pointer;
`;

const DescriptionRow = styled.div`
  font-family: 'Courier Prime', sans-serif;
  font-size: 0.8em;
`;

const Description = styled.p`
  margin: 0;
  white-space: pre-wrap;
`;

const Weight = styled.p`
  &:before {
    content: '[';
  }

  &:after {
    content: ']';
  }

  margin: 0;
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const PriceColumn = styled.div`
  min-width: 50px;
  text-align: right;
`;

const Price = styled.span`
  font-weight: bold;
  font-size: 0.9em;
`;

const SecondaryLabel = styled.span`
  &:before {
    content: '|';
    display: inline-block;
    color: ${({theme}) => theme.colors.dark};
    margin-right: 0.1em;
  }

  font-size: 0.917em;
  text-transform: uppercase;
  font-weight: 600;
`;

const Icon = styled.img`
`;

const DrinkIcon = styled.img`
  height: 1em;
`;

const CTAButton = styled.button`
  font-family: 'Montserrat', sans-serif;
  font-size: 0.6em;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid ${({theme}) => theme.colors.dark};
  border-radius: 1px;
  color: ${({theme}) => theme.colors.dark};
  background: ${({theme}) => theme.colors.light};
  padding: 0.7em 1em;
`;

const CTAButtonWrapper = styled.div`

`;

interface Props {
  block: CustomBlock;
  hideRelatedProducts?: boolean;
}

const noDetailsAvailable = (product: Product) => {
  if (!product.thumbnail && product.allergens.length === 0 && product.nutrition.length === 0) {
    return true;
  }

  return false;
}

const ProductItem: FC<Props> = ({
  block,
  hideRelatedProducts = false
}) => {
  const [productDetailsModalOpen, setProductDetailsModalOpen] = useState<boolean>(false);
  const [relatedProductsModalOpen, setRelatedProductsModalOpen] = useState<boolean>(false);
  const {
    locale
  } = useContext(I18NContext);

  const item = useMemo<Product | null>(() => cleanProductData(block, locale), [block, locale]);
  const handleProductDetailsModalOpen = () => {
    if(!item) {
      return;
    }

    if(noDetailsAvailable(item)) {
      return;
    }
    setProductDetailsModalOpen(true);
  }

  const handleProductDetailsModalClose = () => {
    setProductDetailsModalOpen(false);
  }

  const handleRelatedProductsSelected = () => {
    setRelatedProductsModalOpen(true);
  }

  const handleRelatedProductsModalClose = () => {
    setRelatedProductsModalOpen(false);
  }

  if(!item) {
    return null;
  }

  return (
    <>
      {productDetailsModalOpen && (
        <ProductDetails
          item={item}
          onClose={() => handleProductDetailsModalClose()}
        />
      )}

      {relatedProductsModalOpen && (
        <RelatedProducts
          item={item}
          onClose={() => handleRelatedProductsModalClose()}
        />
      )}
      <Wrapper>
        <InfoColumn>
          <TitleRow>
            <Title
              onClick={() => !hideRelatedProducts && handleProductDetailsModalOpen()}
              disabled={noDetailsAvailable(item)}
            >
              {item.name}
            </Title>
            {item.secondaryLabel && <SecondaryLabel>{item.secondaryLabel}</SecondaryLabel>}
            {item.primaryIcon && <DrinkIcon src={getImagePath(`/assets/icons/${item.primaryIcon}.svg`)} alt="primary-icon"/>}
            {item.secondaryIcon && <DrinkIcon src={getImagePath(`/assets/icons/${item.secondaryIcon}.svg`)} alt="secondary-icon"/>}
            {item.isVegan && <Icon src={getImagePath("/assets/images/vegetarian-product-icon.svg")} alt="vegetarian"/>}
            {item.isSpicy && <Icon src={getImagePath("/assets/images/spicy-product-icon.svg")} alt="vegetarian"/>}
          </TitleRow>

          <DescriptionRow>
            <Description>{item.description}</Description>
            {item.weight && <Weight>{item.weight}</Weight>}
          </DescriptionRow>


          {item.relatedProducts.length > 0 && !hideRelatedProducts && (
            <CTAButtonWrapper>
              <CTAButton onClick={() => handleRelatedProductsSelected()}>Goes With</CTAButton>
            </CTAButtonWrapper>
          )}
        </InfoColumn>

        <PriceColumn>
          <Price>{item.price} lei</Price>
        </PriceColumn>
      </Wrapper>
    </>
  )
}

export default ProductItem;
