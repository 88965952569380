import React, { FC, PropsWithChildren } from 'react';
import styled from "styled-components";

const Wrapper = styled.header`
  border: 3px solid ${({ theme }) => theme.colors.dark};
  background: ${({ theme }) => theme.colors.light};
 `;

const HiddenTitle = styled.h1`
  display: none;
`;

const VisibleTitle = styled.h2`
  font-weight: 700;
  font-size: 4.33rem;
  text-align: center;
  margin-top: 2.37rem;
  margin-bottom: 1.246rem;
  line-height: 1;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
`;

interface Props {
  hiddenTitle?: string;
}

const Header: FC<PropsWithChildren<Props>> = ({
  hiddenTitle,
  children,
}) => {
  return (
    <Wrapper>
      <HiddenTitle>{hiddenTitle}</HiddenTitle>
      <VisibleTitle>{children}</VisibleTitle>
    </Wrapper>
  );
}

export default Header;
