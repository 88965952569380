import styled from "styled-components";

export const Content = styled.main<{
  gap?: number;
  justifyContent?: string;
}>`
  border-left: 3px solid ${({theme}) => theme.colors.dark};
  border-right: 3px solid ${({theme}) => theme.colors.dark};
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent = 'center' }) => justifyContent};
  align-items: center;

  padding: 1rem 0;
  gap: ${({ gap = 2 }) => `${gap}rem`};
`;
