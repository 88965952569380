import React, { FC } from "react";
import { Location, ParentCategory } from "./types";
import styled from "styled-components";
import CategoryHeader from "./CategoryHeader";
import useProductListing from "./useProductListing";
import SubcategoryRenderer from "./SubcategoryRenderer";
import Footer from "../layout/Footer";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import Container from "../layout/Container";
import Loader from "../layout/Loader";
import { sortByCustomRules } from "../api";

const CategoryWrapper = styled.div``;

interface Props {
}

const Menu: FC<Props> = () => {

  const { location } = useParams();

  const {
    content,
    loading
  } = useProductListing({
    location: location as Location,
  });


  if (loading) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      {Object.values(content).map((_category) => {
        const category: ParentCategory = _category;
        return (
          <CategoryWrapper key={category.slug}>
            <CategoryHeader>
              <FormattedMessage
                id={`category.${category.slug}.name`}
                defaultMessage={category.name}
              />
            </CategoryHeader>
            {category.subcategories.sort(sortByCustomRules(category.slug)).map((subcategory) => {
              if(subcategory.blocks.length === 0) {
                return null;
              }
              return (
                <SubcategoryRenderer
                  subcategory={subcategory}
                  key={subcategory.slug}
                />
              );
            })}
          </CategoryWrapper>
        );
      })}
      <Footer />
    </Container>
  );
}

export default Menu;
