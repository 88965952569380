import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

export default Container;
