declare global {
  interface Window {
    themePath: any;
  }
}

export const getImagePath = (path: string) => {
  if (window.themePath) {
    return `${window.themePath}${path}`
  }
  return path;
}

export default getImagePath;
