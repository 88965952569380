import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import getImagePath from "../../utils";

const Halo = styled.img`
  width: 100%;
  max-width: 250px;
  //height: 21px;
`;
const Wrapper = styled.div`
  border: 3px solid ${({ theme }) => theme.colors.dark};
  border-bottom: none;
  display: flex;
  justify-content: center;
  padding: 1em 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.h2`
  font-weight: 700;
  font-size: 4.33rem;
  text-align: center;
  margin-bottom: 0;
  line-height: 1;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
`;
const CategoryHeader: FC<PropsWithChildren> = ({
  children
}) => {
  return (
    <Wrapper>
      <Container>
        <Halo src={getImagePath("/assets/images/halo.svg")} />
        <Heading>{children}</Heading>
      </Container>
    </Wrapper>
  )
}

export default CategoryHeader;
