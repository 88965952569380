import React, { FC, PropsWithChildren } from "react";
import styled from 'styled-components';
import unescape from 'lodash/unescape';

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.light};
  border: 3px solid ${({ theme }) => theme.colors.dark};
  text-align: center;
  padding: 0.9em;
  margin: -1.5px 0;
`;

const Header = styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: underline;
  margin-bottom: 0;
`;

const Subheader = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0;
`;

const HeadingLink = styled.a`
  color: ${({ theme }) => theme.colors.dark};
  
  &:hover {
    color: ${({ theme }) => theme.colors.dark};
  }
`;

const subtitleIsLink = (subtitle?: string) => {
  if(!subtitle) {
    return false;
  }

  return subtitle.startsWith('http');
}

interface Props {
  subtitle?: string;
}
const SubcategoryHeader: FC<PropsWithChildren<Props>> = ({
  children,
  subtitle,
}) => {
  if(subtitleIsLink(subtitle)) {
    return (
      <Wrapper>
        <HeadingLink href={unescape(subtitle)} target="_blank">
          <Header>{children}</Header>
        </HeadingLink>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Header>{children}</Header>
      {subtitle && <Subheader>{unescape(subtitle)}</Subheader>}
    </Wrapper>
  );
}

export default SubcategoryHeader;
