import React, { FC } from "react";
import styled from 'styled-components';
import getImagePath from "../../utils";
import Header from "../layout/Header";
import { FormattedMessage } from "react-intl";
import { Link as OriginalLink } from "react-router-dom";
import { motion } from "framer-motion";
import Footer from "../layout/Footer";
import { Content as OriginalContent } from "../layout/Content";

const Wrapper = styled.div`
  background-image: url(${getImagePath("/background.png")});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const Link = styled(OriginalLink)`
  display: flex;
  justify-content: center;
`;

const IconWrapper = styled(motion.div)`
  background: ${({ theme }) => theme.colors.light} no-repeat center;
  border: 3px solid ${({ theme }) => theme.colors.dark};
  width: 50%;
  height: 50%;
  padding: 1.5rem;

  @media (min-width: 868px) {
    max-width: 20%;
  }
`
export const Icon = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
`;

const Content = styled(OriginalContent)`
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

interface Props {
  legacyMode?: boolean;
}

const LandingPageV2: FC<Props> = ({
  legacyMode = false,
}) => {
  return (
    <Wrapper>
      <Header>
        <FormattedMessage id="menu" />
      </Header>
      <Content>
          <IconWrapper
            animate={{
              opacity: [0, 1],
              y: [40, 0],
            }}
          >
            <Link to="the-saint">
                <Icon src={getImagePath("/the-saint.png")} />
            </Link>
          </IconWrapper>
          <IconWrapper
            animate={{
              y: [40, 0],
              opacity: [0, 1],
              transition: {
                delay: 0.1
              }
            }}
          >
            <Link to="the-other-saint">
                <Icon src={getImagePath("/the-other-saint.png")} />
            </Link>
          </IconWrapper>
      </Content>
      <Footer hideLanguageSelector={legacyMode} />
    </Wrapper>
  )
}

export default LandingPageV2;
