import ProductItem from "./product-item";
import { FC } from "react";
import { CustomBlock } from "./types";
import styled from "styled-components";
import { Separator } from "../layout/common";
import unescape from "lodash/unescape";

interface Props {
  block: CustomBlock;
}

const Paragraph = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5em;
  font-weight: 500;
  text-decoration: underline;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
`;


const BlockRenderer: FC<Props> = ({
  block,
}) => {
  switch (block.name) {
    case "core/paragraph":
      // TODO: Cleanup empty paragraphs on save
      if(!block.attributes.content) {
        return null;
      }
      return (
        <Paragraph>{unescape(block.attributes.content)}</Paragraph>
      );
    case "create-block/the-saint-product-block":
      if (!block.attributes.product) {
        return null;
      }

      return (
        <ProductItem
          key={block.clientId}
          block={block}
        />
      );
    case "core/separator":
      return (
        <Separator />
      );
    default:
      return (
        <p>default</p>
      );
  }
}

export default BlockRenderer;
