import React, { FC } from "react";
import getImagePath from "../../../utils";
import { Link } from "react-router-dom";
import {
  Icon,
  IconWrapper,
  SubcategoryButtonWrapper,
} from "../common";
import { FormattedMessage } from "react-intl";

const TheOtherSaintCategories: FC = () => {
  return (
    <>
      <IconWrapper>
        <Icon src={getImagePath("/the-other-saint.png")}/>
      </IconWrapper>

      <SubcategoryButtonWrapper decoration>
        <Link to={"food"}>
          <FormattedMessage id="category.food.name"/>
        </Link>
      </SubcategoryButtonWrapper>
      <SubcategoryButtonWrapper decoration>
        <Link to={"drinks"}>
          <FormattedMessage id="category.drinks.name"/>
        </Link>
      </SubcategoryButtonWrapper>
      {/*<SubcategoryButtonWrapper>*/}
      {/*  <StoryButton>*/}
      {/*    <FormattedMessage id="category.story.name" />*/}
      {/*  </StoryButton>*/}
      {/*</SubcategoryButtonWrapper>*/}
    </>
  );
}

export default TheOtherSaintCategories;
