import styled from "styled-components";

const Heading = styled.h2`
  font-weight: bold;
  font-size: 1.5em;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
`

export default Heading;
