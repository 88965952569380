import React, { FC, useEffect, useState } from 'react';
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { getSubcategories } from "../api";
import { Content } from "../layout/Content";
import Container from "../layout/Container";
import Loader from "../layout/Loader";

const SubcategoryItem = styled.p`
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  text-decoration: underline;
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: bold;
  
  > a {
    color: ${({ theme }) => theme.colors.dark};
  }
`

interface Subcategory {
  id: number;
  name: string;
  slug: string;
}

const Subcategories: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const {
    location,
    category,
  } = useParams<{location: string, category: string}>();
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);

  useEffect(() => {
    setLoading(true);
    getSubcategories({
      location,
      category
    })
      .then((data) => {
        setSubcategories(data);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  if (loading) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <FormattedMessage id={`category.${category}.name`} />
      </Header>

      <Content>
        {subcategories.map((subcategory) => {
          if(subcategory.slug === 'cigarettes') {
            return (
              <SubcategoryItem key={subcategory.id}>
                <a
                  href="https://experiencebat.ro/locationbrands"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id={`menu.subcategory.${subcategory.slug}.title`}/>
                </a>
              </SubcategoryItem>
            )
          }
          return (
            <SubcategoryItem key={subcategory.id}>
              <Link to={subcategory.slug}>
                <FormattedMessage id={`menu.subcategory.${subcategory.slug}.title`}/>
              </Link>
            </SubcategoryItem>
          );
        })}
      </Content>
      <Footer />
    </Container>
  )
}

export default Subcategories;
