import React, { FC, useContext } from "react";
import styled from "styled-components";
import { I18NContext } from "./index";
import { Locale } from "./types";

const Wrapper = styled.div`

`;

const LocaleButton = styled.button<{ isActive: boolean }>`
  font-size: 1.75em;
  color: ${({theme, isActive}) => (isActive ? theme.colors.primary : theme.colors.dark)};
  text-decoration: underline;
  border: none;
  background: none;
  font-family: 'Montserrat', monospace;
`;

const LanguageSelector: FC = () => {
  const {
    locale,
    setLocale
  } = useContext(I18NContext);

  return (
    <Wrapper>
      <LocaleButton
        onClick={() => setLocale(Locale.RO)}
        isActive={locale === Locale.RO}
      >
        RO
      </LocaleButton>
      <LocaleButton
        onClick={() => setLocale(Locale.EN)}
        isActive={locale === Locale.EN}
      >
        EN
      </LocaleButton>
    </Wrapper>
  );
}

export default LanguageSelector;
