import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router";
interface Args {
  onBack: () => void;
}

type Hook = (args: Args) => void;
const useBrowserBackBlocking: Hook = ({
  onBack,
}) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {

    // @ts-ignore broken types
    const unblock = navigator.block((tx) => {
      if (tx.action === 'PUSH') {
        unblock();
        tx.retry();
        return;
      }
      // eslint-disable-next-line
      // @ts-ignore
      const currentScrollTop = document.scrollingElement.scrollTop;

      // @ts-ignore
      document.scrollingElement.scrollTop = currentScrollTop;
      onBack()
    });

    return () => unblock();
    // eslint-disable-next-line
  }, [navigator]);

}

export default useBrowserBackBlocking;
