import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { Modal as BSModal } from 'reactstrap';

const ModalBody = styled.div`
  border: 3px solid ${({theme}) => theme.colors.dark};
  border-bottom: none;
  background: ${({theme}) => theme.colors.light};
  width: 100%;
  max-width: 400px;
  margin: 4em;
  
  @media (max-width: 768px) {
    margin: 0;
  }
`;

const Section = styled.div`
  border-bottom: 3px solid ${({theme}) => theme.colors.dark};
`;

export const Header = styled.h2<{ variant?: 'primary' | 'default', large?: boolean }>`
  text-transform: uppercase;
  font-size: ${({ large = false }) => large ? '3em' : '2em'};
  margin-bottom: 1.9em;
  text-align: center;
  font-weight: 700;
  color: ${({theme, variant}) => (variant === 'primary' ? theme.colors.primary : theme.colors.dark)};
  font-family: 'Montserrat', sans-serif;
`;

const CloseButton = styled.button`
  background: url("/assets/images/close.png");
  border: none;
  width: 25px;
  height: 25px;
  background-size: contain;
`;

const HeaderTopWrapper = styled.div`
  text-align: right;
  padding: 1em 1em 0;
`;

export const SectionContainer = styled.div`
  padding: 3em 2em;
`;

export const Picture = styled.img`
  width: 100%;
  aspect-ratio: 1/1;
  border: 3px solid ${({theme}) => theme.colors.dark};
  object-fit: cover;
`;

interface Props {
  header: string;
  onClose: () => void;
  largeHeader?: boolean;
}

const Modal: FC<PropsWithChildren<Props>> = ({
  children,
  onClose,
  header,
  largeHeader = false,
}) => {
  return (
    <BSModal isOpen toggle={onClose}>
      <ModalBody>
        <Section>
          <HeaderTopWrapper>
            <CloseButton onClick={onClose}/>
          </HeaderTopWrapper>
          <Header large={largeHeader}>
            {header}
          </Header>
        </Section>
        {children}
      </ModalBody>
    </BSModal>
  );
}

export default Modal;
