import React, { FC, useEffect, useRef } from "react";
import SubcategoryHeader from "./SubcategoryHeader";
import styled from "styled-components";
import BlockRenderer from "./BlockRenderer";
import { CustomSubcategory } from "./types";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import unescape from "lodash/unescape";

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8em;
  padding: 4em 2.5em;
  border: 3px solid ${({ theme }) => theme.colors.dark};
  margin: -1.5px 0;
  border-bottom: none;
  border-top: none;
`;

interface Props {
  subcategory: CustomSubcategory;
}
const SubcategoryRenderer: FC<Props> = ({
  subcategory,
}) => {
  const ref = useRef<HTMLElement>(null);
  const {
    subcategory: subcategorySlug,
  } = useParams();

  useEffect(() => {
    if (subcategorySlug === subcategory.slug) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section ref={ref}>
      <SubcategoryHeader subtitle={subcategory.description}>
        <FormattedMessage
          id={`menu.subcategory.${subcategory.slug}.title`}
          defaultMessage={unescape(subcategory.name)}
        />
      </SubcategoryHeader>
      <ItemsWrapper>
        {subcategory.blocks.map((block, index) => (
          <BlockRenderer
            key={`${block.clientId}-${index}`}
            block={block}
          />
        ))}
      </ItemsWrapper>
    </section>
  );
}

export default SubcategoryRenderer;
