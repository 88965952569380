import Modal, { SectionContainer } from "../../../layout/Modal";
import React, { FC, useEffect, useState } from "react";
import { CustomBlock, Product } from "../../types";
import ProductItem from "../index";
import styled from "styled-components";
import { getRelatedProducts } from "../../../api";
import ModalSection from "../../../layout/Modal/ModalSection";
import useBrowserBackBlocking from "../../useBrowserBackBlocking";

interface Props {
  item: Product;
  onClose: () => void;
}

const RelatedProductsContainer = styled(SectionContainer)`
  display: flex;
  flex-direction: column;
  gap: 1.8em;
`;

const Separator = styled.hr`
  width: 24px;
  border-top: 3px solid ${({ theme }) => theme.colors.dark};
  opacity: 1;
  margin-left: auto; 
  margin-right: auto;
`;

const RelatedProducts: FC<Props> = ({
  item,
  onClose,
}) => {
  const [relatedProducts, setRelatedProducts] = useState<CustomBlock[]>([]);

  useBrowserBackBlocking({
    onBack: () => onClose(),
  });

  useEffect(() => {
    getRelatedProducts(item.id)
      .then((data) => setRelatedProducts(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      header="Goes With"
      largeHeader
      onClose={() => onClose()}
    >
      <ModalSection>
        <RelatedProductsContainer>
          {relatedProducts.map((block, index) =>
            (
              <>
                <ProductItem
                  block={block}
                  key={block.attributes.post.ID}
                  hideRelatedProducts
                />
                {index !== relatedProducts.length - 1 && <Separator />}
              </>
            ))}
        </RelatedProductsContainer>
      </ModalSection>
    </Modal>
  )
}

export default RelatedProducts;
