import { useEffect, useMemo, useState } from "react";
import { GroupedCategories, Location, ParentCategory } from "./types";
import { WP_Term } from "wp-types";
import { getMenuPageBlocks } from "../api";

function groupByParentOptions(categories: WP_Term[]): GroupedCategories {
  const result: GroupedCategories = {};
  categories.forEach((category) => {
    if (category.parent !== 0) {
      if (!result[category.parent]) {
        const parentCategory: ParentCategory = {
          name: categories.find((c) => c.term_id === category.parent)?.name || "",
          slug: categories.find((c) => c.term_id === category.parent)?.slug || "",
          subcategories: [],
        };
        result[category.parent] = parentCategory;
      }
      // @ts-ignore
      result[category.parent].subcategories.push(category);
    }
  });
  return result;
}
interface Args {
  location: Location;
}

interface Fields {
  content: GroupedCategories;
  loading: boolean;
}

type Hook = (args: Args) => Fields;

const useProductListing: Hook = ({
  location
}) => {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMenuPageBlocks(location)
      .then(data => {
        setLoading(false);
        setData(data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = useMemo(() => groupByParentOptions(data), [data]);

  return {
    content,
    loading,
  }
}

export default useProductListing;
