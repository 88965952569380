import React, { FC, useMemo } from "react";
import { Product } from "../../types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const Wrapper = styled.div`
  padding: 3em 2em;
  padding-top: 0;
  font-family: 'Montserrat', sans-serif;
`;

const Notice = styled.p`
  font-family: 'Courier Prime', serif;
  text-transform: lowercase;
  font-size: 0.8em;
`;

const Table = styled.div`
  border: 3px solid ${({ theme }) => theme.colors.dark};
`;

const Row = styled.div<{ heavy?: boolean, compact?: boolean }>`
  border-bottom: ${({ heavy = false }) => heavy ? '3px' : '1px'} solid ${({ theme }) => theme.colors.dark};
  padding: ${({ compact = false }) => compact ? '3px 6px' : '9px 6px'};
`;

const ProductWeight = styled.div`
  font-size: 0.9em;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProductCalories = styled.div`
  font-size: 1.1em;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
`

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Label = styled.span`
  font-size: 1rem;
  font-family: 'Courier Prime', serif;
`;

const Value = styled.span`
  font-size: 1rem;
  font-family: 'Courier Prime', serif;
`;

const BigValue = styled.span`
  font-size: 1.333rem;
`;

const Bigger = styled.span`
  font-size: 1.3rem;
  text-align: left;
`;
interface Props {
  product: Product;
}

const notEnergy = (item: Record<string, string>) => item.name !== 'product_nutrition_energy';

const NutritionalValuesTable: FC<Props> = ({
  product,
}) => {
  const energy = useMemo(() => product.nutrition.find(item => item.name === 'product_nutrition_energy'), [product]);

  return (
    <Wrapper>
      <Table>
        <Row heavy>
            <Notice>
              <FormattedMessage
                id="menu.product.details.nutritional.values.note"
              />
            </Notice>
            <ProductWeight>
              <Label>
                <FormattedMessage id="menu.product.details.weight.title" />:
              </Label>
              <BigValue>{product.weight}</BigValue>
            </ProductWeight>
        </Row>
        <Row heavy>
          <ProductCalories>
            <Bigger>
              <FormattedMessage id="menu.product.nutrition.product_nutrition_energy.label" />:
            </Bigger>
            <Bigger>{energy?.value}</Bigger>
          </ProductCalories>
        </Row>
        {product.nutrition.filter(notEnergy).map((item, index) => (
          <Row compact key={index}>
            <RowContainer>
              <Label>
                <FormattedMessage id={`menu.product.nutrition.${item.name}.label`} />:
              </Label>
              <Value>{item.value}</Value>
            </RowContainer>
          </Row>
        ))}
      </Table>
    </Wrapper>
  );
}

export default NutritionalValuesTable;
