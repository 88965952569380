import { FC } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const Wrapper = styled.div`
  font-family: 'Courier Prime', serif;
  font-weight: bold;
  text-align: center;
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0;
  margin: 0;
`;

const ListItem= styled.li`
  font-size: 1em;
`;
interface Props {
  items: string[];
  frozenAllergens: string;
}
const AllergensList: FC<Props> = ({
  items,
  frozenAllergens,
}) => {
  return (
    <Wrapper>
      <List>
        {items.map((item, index) => (
          <ListItem key={item}>
            <FormattedMessage id={`menu.product.allergens.${item}.label`} />
          </ListItem>
        ))}

        {frozenAllergens && (
          <ListItem>
            <FormattedMessage
              id="menu.product.allergens.frozen.label"
              values={{
                productsName: frozenAllergens
            }}
            />

          </ListItem>
        )}
      </List>
    </Wrapper>
  )
}

export default AllergensList;
