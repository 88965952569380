import styled from "styled-components";

export const Separator = styled.hr`
  border: 0;
  border-top: 3px solid #000;
  opacity: 1;
  width: 100%;
  max-width: 50px;
  margin-left: auto;
  margin-right: auto;
`;
