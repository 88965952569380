import styled from "styled-components";

const Subheading = styled.h3`
  font-weight: 500;
  font-size: 1.25em;
  color: ${({ theme }) => theme.colors.dark};
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
`

export default Subheading;
