import styled, { css } from "styled-components";
import getImagePath from "../../utils";

export const SubcategoryButtonWrapper = styled.div<{ decoration?: boolean }>`
  font-size: 2.75rem;
  text-transform: uppercase;
  text-decoration: underline;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  
  ${({ decoration = false }) => !decoration && css`
    margin-top: 20px;
  `};
  
  ${({ decoration = false }) => decoration && css`
    &:before {
      content: '';
      display: block;
      background: url(${getImagePath("/assets/images/halo.svg")}) no-repeat center;
      background-size: contain;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: -10px;
      width: 144px;
      height: 40px;
    }
  `};
  
  > a, button {
    color: ${({ theme }) => theme.colors.dark};
    font-weight: bold;
  }
`

export const StoryButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: bold;
`;


export const IconWrapper = styled.div`
  text-align: center;
`;

export const Icon = styled.img`
  height: 100px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;


export const Sponsors = styled.div`
  //max-width: 78px;
  //margin: 0;
  border-left: 3px solid ${({ theme }) => theme.colors.dark};
  border-right: 3px solid ${({ theme }) => theme.colors.dark};
  width: 100%;
  padding: 1em
`;

export const Sponsor = styled.div`
  margin: 0 auto;
  max-width: 100px;
`;

export const SponsorImage = styled.img`
  width: 100%;
`;
