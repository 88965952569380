import React, { FC } from "react";
import getImagePath from "../../../utils";
import { Link } from "react-router-dom";
import { Icon, IconWrapper, SubcategoryButtonWrapper } from "../common";
import { FormattedMessage } from "react-intl";
import HappyHour from "./HappyHour";

const TheSaintCategories: FC = () => {
  return (
    <>
      <IconWrapper>
        <Icon src={getImagePath("/the-saint.png")}/>
      </IconWrapper>

      <HappyHour/>

      <SubcategoryButtonWrapper decoration>
        <Link to="food">
          <FormattedMessage id="category.food.name"/>
        </Link>
      </SubcategoryButtonWrapper>
      <SubcategoryButtonWrapper decoration>
        <Link to="drinks">
          <FormattedMessage id="category.drinks.name"/>
        </Link>
      </SubcategoryButtonWrapper>
      {/*<SubcategoryButtonWrapper>*/}
      {/*  <StoryButton>*/}
      {/*    <FormattedMessage id="category.story.name" />*/}
      {/*  </StoryButton>*/}
      {/*</SubcategoryButtonWrapper>*/}
    </>

  );
}

export default TheSaintCategories;
