import { CustomBlock } from "./types";
import { Locale } from "../i18n/types";

const getLocalizedField = (fieldSet: any, fieldName: string, locale: Locale) => {
  if(
    fieldSet[`${fieldName}_${locale}`] &&
    fieldSet[`${fieldName}_${locale}`].length > 0
  ) {
    return fieldSet[`${fieldName}_${locale}`][0];
  }

  return;
}

const getField = (fieldSet: any, fieldName: string) => {
  if(
    fieldSet[fieldName] &&
    fieldSet[fieldName].length > 0
  ) {
    return fieldSet[fieldName][0];
  }

  return;
}

const getRelatedProducts = (fieldSet: any) => {
  if(fieldSet.product_related_products && fieldSet.product_related_products.length > 0) {
    return fieldSet.product_related_products.filter((productId: string) => !!productId);
  }

  return [];
}

//
// const allergensList = [
//   "product_food_allergens_celery",
//   "product_food_allergens_crustaceans",
//   "product_food_allergens_eggs",
//   "product_food_allergens_fish",
//   "product_food_allergens_gluten",
//   "product_food_allergens_lupin",
//   "product_food_allergens_milk",
//   "product_food_allergens_molluscs",
//   "product_food_allergens_mustard",
//   "product_food_allergens_nuts",
//   "product_food_allergens_peanuts",
//   "product_food_allergens_sesame",
//   "product_food_allergens_soybeans",
//   "product_food_allergens_sulfur_dioxide",
// ];

const getAllergens = (fieldSet: any) => {
  return Object.keys(fieldSet).reduce((acc: string[], allergen: string) => {
    if(allergen.indexOf('product_food_allergens_') !== -1 && !!fieldSet[allergen][0]) {
      return [...acc, allergen]
    }
    return acc;
  }, []);
}

const getNutrition = (fieldSet: any) => {
  return Object.keys(fieldSet).reduce((acc: Record<string, string>[], nutritionalValue: string) => {
    if(nutritionalValue.indexOf('product_nutrition_') !== -1 && !!fieldSet[nutritionalValue][0]) {
      return [
        ...acc,
        {
          name: nutritionalValue,
          value: fieldSet[nutritionalValue][0]
        }
      ]
    }
    return acc;
  }, []);
}
export function cleanProductData(block: CustomBlock, locale: Locale) {
  if(!block.attributes.parentCategory) {
    return null;
  }
  if(block.attributes.parentCategory.slug === 'drinks') {
    return {
      id: block.attributes.post.ID,
      name: getLocalizedField(block.attributes.product, 'product_name', locale),
      price: getField(block.attributes.product, 'product_price'),
      description: getLocalizedField(block.attributes.product,'product_description', locale),
      secondaryLabel: getLocalizedField(block.attributes.product, 'product_drink_label', locale),
      weight: getField(block.attributes.product, 'product_drink_weight'),
      isSpicy: false,
      isVegan: false,
      relatedProducts: getRelatedProducts(block.attributes.product),
      thumbnail: block.attributes.productImage,
      allergens: getAllergens(block.attributes.product),
      frozenAllergens: getLocalizedField(block.attributes.product, 'product_food_frozen_allergen', locale),
      nutrition: [],
      primaryIcon: getField(block.attributes.product, 'product_drink_primary_icon'),
      secondaryIcon: getField(block.attributes.product, 'product_drink_secondary_icon'),
    }
  }

  return {
    id: block.attributes.post.ID,
    name: getLocalizedField(block.attributes.product, 'product_name', locale),
    price: getField(block.attributes.product, 'product_price'),
    description: getLocalizedField(block.attributes.product,'product_description', locale),
    secondaryLabel: getLocalizedField(block.attributes.product, 'product_food_origin_country', locale),
    weight: getField(block.attributes.product, 'product_food_weight'),
    isSpicy: getField(block.attributes.product, 'product_food_is_spicy'),
    isVegan: getField(block.attributes.product, 'product_food_is_vegan'),
    relatedProducts: getRelatedProducts(block.attributes.product),
    thumbnail: block.attributes.productImage,
    allergens: getAllergens(block.attributes.product),
    frozenAllergens: getLocalizedField(block.attributes.product, 'product_food_frozen_allergen', locale),
    nutrition: getNutrition(block.attributes.product),
    primaryIcon: null,
    secondaryIcon: null,
  }
}
